import logo from './logo.svg';
import './App.css';
import Collection from './pages/collection/collectionUser'
import Index from "./pages";
import SelectTreeA from './pages/collection/index'
import {NavLink, Outlet, Route, Router, useNavigate, useRoutes} from "react-router-dom";
import routes from "./routes";
import Routes from "./routes";
import Routers from "./routes";
import rRouter from "./routes/sub";
import {useState} from "react";
import Success from "./pages/response/success";

function App() {
    const element = useRoutes(routes)
    const [isShow,setIsShow] = useState(true)
    // const element1 = useNavigate(rRouter)
    // const navigate = useNavigate();
    // navigate("/path/collection")
    return (
        <div className="App">
            {isShow?<Collection setIsShowCollection={setIsShow} />:<Success/>}
            {/*<div className="row">*/}
            {/*    <div className="col-xs-6">*/}
            {/*        <div className="panel">*/}
            {/*            <div className="panel-body">*/}
            {/*                /!*注册路由*!/*/}
            {/*                {element}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    );
}
function generateRoute(routes) {
    return routes.map((item) => {
        if (!item?.children) {
            return <Route key={item.path} path={item.path} element={item.element}/>
        } else {
            return <Route key={item.path}>{generateRoute(item.children)}</Route>
        }
    })
}

function generaterRoute(routes ) {
    return routes.map((item) => {
        return (
            <Route key={item.path} path={item.path} element={item.element}>
                {item?.children.map((item2) => {
                    return (
                        <Route
                            key={item2.path}
                            path={item2.path}
                            element={item2.element}
                        ></Route>
                    );
                })}
            </Route>
        );
    })
}

export default App;
