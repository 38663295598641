/**
 * @description: success.jsx
 * @author: Jory
 * @copyright: Copyright SanFu, Inc
 * @date: 2023/03/11 星期六 00:54:58
 */

import {Button, Form, Input, message, Select, Checkbox, Row, Col, Modal} from 'antd';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useRequest} from 'ahooks';
import '../../css/normalize.css'
import '../../css/index.css'
import axios from "axios";
import AntuliImg from '../../img/antuli1.png'
import SuccessImg from '../../img/success.png'

export default function Success() {

    /*状态 START*/

    /*状态 END*/

    /*数据初始化 START*/

    /*数据初始化 END*/

    /*useRequest START*/

    /*useRequest END*/

    /*事件回调 START*/

    /*事件回调 END*/

    return (
        <>
            <img src={AntuliImg} style={{height: 300,margin:'0 auto',display:"block",marginTop:50}}/>
            <img src={SuccessImg} style={{height: 100,marginTop:30}}/>
            <div style={{color:"green",marginTop:20,fontSize:30,fontWeight:"bold"}}>信息录入成功</div>
        </>
    );
}