import Success from "../pages/response/success";
import CollectionUser from "../pages/collection/collectionUser";
import Index from "../pages/index";
const Routers = [
    // {
    //     path:'/path',
    //     element: <Index />,
    //     children: [{
    //         path:'/path/collection',
    //         element: <CollectionUser/>,
    //     },
    //     {
    //         path: '/path/success',
    //         element: <Success/>
    //     }]
    // },
    {
        path:'/collection',
        element: <CollectionUser/>,
    },
    {
        path: '/success',
        element: <Success/>
    }
]

export default Routers;
