/**
 * @description: collectionUser.tsx
 * @author: Jory
 * @copyright: Copyright SanFu, Inc
 * @date: 2023/02/04 星期六 20:01:46
 */

import {Button, Form, Input, message, Select, Checkbox, Row, Col, Modal, TreeSelect, Image} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {Link, NavLink, useNavigate, useRoutes} from "react-router-dom";
import {useRequest} from 'ahooks';
import '../../css/normalize.css'
import '../../css/index.css'
import axios from "axios";
import Antuli from '../../img/antuli.jpg'
import AntuliImg from '../../img/antuli1.png'
import routes from "../../routes";
import rRouter from "../../routes/sub";

const validateMessages = {
    required: "${label} 必填!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const {SHOW_ALL} = TreeSelect;

export default function CollectionUser(props) {
    const {setIsShowCollection} = props;
    const navigate = useNavigate();
    /*状态 START*/
    const userName = useRef();//用户名
    const idCard = useRef();//身份证
    const mobile = useRef();//手机号
    const wechatNo = useRef();//微信号码
    const address = useRef();//地址
    const [isShow, setIsShow] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [lastData, setLastData] = useState([]);
    const [optionsData, setOptionsData] = useState([]);
    const [optionsValue, setOptionsValue] = useState('');//工作经验
    const [professionList, setProfessionList] = useState([]);//工种
    const [professionItemList, setProfessionItemList] = useState([]);//涂料工
    const [value, setValue] = useState([]);
    const [submitRestrict, setSubmitRestrict] = useState([]);
    const [submitBoolean, setSubmitBoolean] = useState(false);
    const [nameCheck, setNameCheck] = useState('');
    const [idCardCheck, setIsCardCheck] = useState('');
    const [mobileCheck, setMobileCheck] = useState('');
    const [wechatCheck, setWechatCheck] = useState('');
    const [addresssCheck, setAddresssCheck] = useState('');
    const [professionCheck, setProfessionCheck] = useState('');
    const [optionsCheck, setOptionsCheck] = useState('');
    /*状态 END*/
    useEffect(() => {
        getProfessionData();
        getExperienceList();
    }, [])
    /*数据初始化 START*/

    /*数据初始化 END*/

    /*useRequest START*/

    /*useRequest END*/

    /*事件回调 START*/
    const save = () => {
        // 关键代码，`current` 指向已挂载到 DOM 上的文本输入元素
        const name = userName.current.value;
        const id = idCard.current.value;
        const mobileNo = mobile.current.value;
        const wechat = wechatNo.current.value;
        const addresss = address.current.value;
        const restriction = [];
        let check = true;
        if (name == '') {
            setNameCheck("姓名不能未空")
            check = false;
        } else {
            setNameCheck("")
        }
        if (id == '') {
            setIsCardCheck("身份证号不能为空")
            check = false;
        } else {
            var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
            if (reg.test(id) === false) {
                setIsCardCheck("身份证号验证错误")
                check = false;
            } else {
                setIsCardCheck("")
            }
        }
        if (mobileNo == '') {
            setMobileCheck("手机号不能为空");
            check = false;
        } else {
            var num_reg = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|16[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|19[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
            if (!num_reg.test(mobileNo)) {
                setMobileCheck("手机号验证失败");
                check = false;
            } else {
                setMobileCheck("");
            }
        }
        if (wechat == '') {
            setWechatCheck("微信手机号不能为空")
            check = false;
        } else {
            setWechatCheck("")
        }
        if (addresss == '') {
            setAddresssCheck("家庭住址不能未空")
            check = false;
        } else {
            setAddresssCheck("")
        }

        const selectOne = professionList.filter(item => item == '1001' || item == '1002')
        const selecTwo = professionList.filter(item => item == '1003' || item == '1004' || item == '1005' || item == '1006' || item == '1007' || item == '1000' || item == '1013')
        if (selectOne.length == 0) {
            setProfessionCheck("包工头和普通需选择一个")
            check = false;
        } else if (selecTwo.length == 0) {
            setProfessionCheck("其他工作必选一个")
            check = false;
        } else {
            setProfessionCheck("")
        }
        if (optionsValue == '') {
            setOptionsCheck("工作经验必选")
            check = false;
        } else {
            setOptionsCheck("")
        }
        setSubmitRestrict(restriction);
        if (check) {
            const dataListProfession = [];
            if (professionList.length > 0){
                for (var i=0;i<professionList.length;i++){
                    dataListProfession.push(professionList[i])
                }
            }
            if (professionItemList.length > 0){
                for (var i=0;i<professionItemList.length;i++){
                    dataListProfession.push(professionItemList[i])
                }
            }

            const params = {
                userName: name,
                idCard: id,
                wechatNo: wechat,
                profession: dataListProfession.toString(),
                mobile: mobileNo,
                experienceId: optionsValue,
                address: addresss
            }
            axios.put('/api/collection/saveOrUpdateCollection', params).then(
                response => {
                    console.log(response.data.message)
                    // alert(response.data.message)
                    // navigate('/success')
                    setIsShowCollection(false);
                },
                error => {
                    alert(error.data.message)
                }
            )
        }
    }
    const onChange = (newValue) => {
        console.log('onChange ', value);
        setValue(newValue);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setOptionsValue(value);
    };
    /*事件回调 END*/

    const getProfessionData = () => {
        axios.get('/api/collection/getProfessionList').then(
            response => {
                const dataList = [];
                const list = response.data.payload;
                const data = list.filter(item => item.key == '1000')[0];
                const all = list.filter(item => item.key != '1000');
                const one = all.filter(item => item.key < parseInt('1003'));
                const tow = all.filter(item => item.key > parseInt('1002'));
                for (var i = 0; i < one.length; i++) {
                    dataList.push(one[i])
                }
                dataList.push(data)
                for (var i = 0; i < tow.length; i++) {
                    dataList.push(tow[i])
                }
                setTreeData(dataList)
            },
            error => {
                alert(error.data.message)
            }
        )
    }

    const getExperienceList = () => {
        axios.get('/api/collection/getExperienceList').then(
            response => {
                console.log(response)
                setOptionsData(getExperienceItem(response.data.payload))
            },
            error => {
                alert(error.data.message)
            }
        )
    }

    const getExperienceItem = (data) => {
        return data?.map((item) => {
            return {
                label: item.description,
                value: item.id
            }
        })
    }

    const clickProfession = (key) => {
        const professions = professionList.filter(item => item == key);
        let list = [];
        if (professions.length > 0) {
            list = professionList.filter(item => item !== key);
        } else {
            list = [...professionList, key]
        }
        setProfessionList(list);
        const data = list.filter(i => {
            return i == '1000';
        })[0]
        const tree = treeData.filter(item => item.key == data)[0]
        if (data != undefined) {
            setIsShow(true)
            setLastData(tree.children);
        } else {
            setIsShow(false);
            setProfessionItemList([]);
        }
    }

    const clickItem = (key) => {
        const professions = professionItemList.filter(item => item == key);
        let list = [];
        if (professions.length > 0) {
            list = professionItemList.filter(item => item !== key);
        } else {
            list = [...professionItemList, key]
        }
        console.log('list', list)
        setProfessionItemList(list);
    }

    const clickExperience = (key) => {
        setOptionsValue(key)
    }

    const tProps = {
        treeData,
        value,
        onChange,
        treeCheckable: true,
        showCheckedStrategy: SHOW_ALL,
        placeholder: '请选择工种',
        style: {
            width: '100%',
        },
    };

    return (
        <div>
            <img src={AntuliImg} style={{height: 300, margin: '0 auto', display: "block", marginTop: 50}}/>
            <label>姓名</label>
            <input className='select_ui' ref={userName}/>
            {nameCheck != '' ? <span style={{color: "red"}}>{nameCheck}</span> : ""}
            <label>身份证号</label>
            <input className='select_ui' ref={idCard}/>
            {idCardCheck != '' ? <span style={{color: "red"}}>{idCardCheck}</span> : ""}
            <label>手机号</label>
            <input className='select_ui' ref={mobile}/>
            {mobileCheck != '' ? <span style={{color: "red"}}>{mobileCheck}</span> : ""}
            <label>微信手机号</label>
            <input className='select_ui' ref={wechatNo}/>
            {wechatCheck != '' ? <span style={{color: "red"}}>{wechatCheck}</span> : ""}
            <label>家庭住址</label>
            <input className='select_ui' ref={address}/>
            {addresssCheck != '' ? <span style={{color: "red"}}>{addresssCheck}</span> : ""}
            <label>工种(多选)</label>
            <div style={{width: 350, maxWidth: '100%', maxHeight: '100%', paddingLeft: 30, height: 70}}>
                {treeData.map(item =>
                    professionList.filter(profession => item.key == profession)[0] != null
                        ? <div className='profession_btn_1' key={item.key}
                               onClick={() => clickProfession(item.key)}>{item.title}</div>
                        : <div className='profession_btn' key={item.key}
                               onClick={() => clickProfession(item.key)}>{item.title}</div>)}
            </div>
            {professionCheck != '' ? <span style={{color: "red"}}>{professionCheck}</span> : ""}
            {isShow ? <div>
                <div>
                    <label>涂料技术工(多选)</label>
                    <div style={{width: 350, maxWidth: '100%', maxHeight: '100%', paddingLeft: 30, height: 70}}>
                        {lastData.map(item =>
                            professionItemList.filter(profession => item.key == profession)[0] != null ?
                                <div className='profession_btn_1' key={item.key}
                                     onClick={() => clickItem(item.key)}>{item.title}</div> :
                                <div className='profession_btn' key={item.key}
                                     onClick={() => clickItem(item.key)}>{item.title}</div>)}

                    </div>
                </div>
            </div> : ""}
            <label>工作经验</label>
            <div style={{width: 350, maxWidth: '100%', maxHeight: '100%', paddingLeft: 30, height: 70}}>
                {optionsData.map(item => item.value == optionsValue
                    ? <div className='profession_btn_1' key={item.value}
                           onClick={() => clickExperience(item.value)}>{item.label}</div>
                    : <div className='profession_btn' key={item.value}
                           onClick={() => clickExperience(item.value)}>{item.label}</div>
                )}
            </div>
            {optionsCheck != '' ? <span style={{color: "red"}}>{optionsCheck}</span> : ""}
            <li>
                <button className='submit_btn' onClick={() => save()}>
                    保存
                </button>
            </li>
        </div>
    );
}